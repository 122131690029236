<template>
  <el-row id="checkout">
      <el-col :span="24" class="checkout-sub-div-m">
        <div v-if="browserFrom === 'app'" style="text-align:center;">
            <i class="el-icon-back" style="font-size:24px;float:left;margin-top:40px;margin-left:0px;font-weight:bold;" @click="backNavClick"></i>
            <el-image fit="fill" class="logo-img-checkout" :src="require('../../assets/ushop3.png')" style="margin-top:30px;margin-left:-10px;"></el-image>
        </div>
        <div v-else><el-image fit="fill" class="logo-img-checkout" :src="require('../../assets/ushop3.png')" style="cursor:pointer;" @click="homePageClick"></el-image></div>
        <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:10px 5px 0px 5px;">
            <el-breadcrumb-item to="/cart">Cart</el-breadcrumb-item>
            <el-breadcrumb-item>Infomation</el-breadcrumb-item>
            <el-breadcrumb-item v-if="showPayment > 0">Payment</el-breadcrumb-item>
            <el-breadcrumb-item v-if="showPayment > 1">Scan To Pay</el-breadcrumb-item>
        </el-breadcrumb>
        <el-collapse accordion style="margin-top:20px;">
            <el-collapse-item v-if="showPayment != 2">
                <template slot="title">
                    <div style="display:-webkit-box;width:100%;">
                        <div style="line-height:48px;font-size:16px;">
                            <i class="el-icon-shopping-cart-full" style="font-size:16px;"></i>  Show Order  Summary
                        </div>
                        <div style="-webkit-box-flex:1;text-align:right;font-weight:bold;font-size:16px;">
                            <span v-if="isMember">${{cartMemberTotal}}</span>
                            <span v-else>${{cartTotal}}</span>
                        </div>
                    </div>
                </template>
                <el-col :span="24" class="checkout-sub-div-2-m">
                    <div class="anouncement-div-2" v-if="showAnounce">Order paid after 5:00PM will take extra one day deliver delay.</div>
                    <checkout-deliver-item v-for="item in goodListByDeliverDate" :key="item.key" :items="item" @onDateChange="onDateChange" :showChange="showPayment == 0"></checkout-deliver-item>
                    <div style="display:-webkit-box;font-size:14px;">
                        <div>Subtotal</div>
                        <div style="-webkit-box-flex:1;text-align:right;font-weight:bold;font-size:14px;">
                            <span v-if="isMember">${{cartMemberTotal}}</span>
                            <span v-else>${{cartTotal}}</span>
                        </div>
                    </div>
                    <div style="display:-webkit-box;margin-top:10px;font-size:14px;">
                        <div>Shipping</div>
                        <div style="-webkit-box-flex:1;text-align:right;font-weight:bold;font-size:14px;">
                            <span v-if="shippingMethod == 'free'"><span style="color:red;text-decoration:line-through;display:float;margin-right:10px;">$6.90</span><span>$0.00</span></span>
                            <span v-if="shippingMethod == 'standard'">$8.00</span>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <div style="display:-webkit-box;font-size:16px;margin-bottom:15px;">
                        <div>Total</div>
                        <div style="-webkit-box-flex:1;text-align:right;font-weight:bold;font-size:16px;">
                            <span v-if="isMember">${{cartMemberTotal}}</span>
                            <span v-else>${{cartTotal}}</span>
                        </div>
                    </div>
                </el-col>
            </el-collapse-item>
        </el-collapse>
        <div v-if="showPayment == 0" id="checkout_infomation">
            <div style="font-size:18px;">Contact Infomation</div>
            <div style="margin-top:20px;">
                <el-input v-model="contactName" placeholder="please input contact name">
                </el-input>
            </div>
            <div style="margin-top:20px;">
                <el-input v-model="contactNumber" placeholder="please input contact name">
                </el-input>
            </div>
            <div style="font-size:18px;margin-top:40px;">Shipping Address</div>
            <div style="margin-top:20px;">
                <el-select v-model="expectedLocation" placeholder="choose location">
                    <el-option v-for="item in selectLocations" :key="item.ID" :label="item.name" :value="item.ID"></el-option>
                </el-select>
                <div style="margin-top:10px;color:grey;font-size:12px;">{{expectedLocationAddress}}</div>
            </div>
            <div style="font-size:18px;margin-top:40px;">Shipping Method</div>
            <div style="margin-top:20px;">
                <div class="checkout-shipping-method-top">
                    <div style="-webkit-box-flex:1;"><el-radio v-model="shippingMethod" label="free">Free Shipping</el-radio></div>
                    <div><span style="color:red;text-decoration:line-through;display:float;margin-right:10px;">$6.90</span><span>$0.00</span></div>
                </div>
                <!--<div class="checkout-shipping-method-bottom">
                    <div style="-webkit-box-flex:1;"><el-radio v-model="shippingMethod" label="standard">Standard Shipping</el-radio></div>
                    <div>$8.00</div>
                </div>-->
            </div>
            <div style="margin-top:20px;display:-webkit-box;">
                <div><el-button type="danger" @click="gotoPaymentClick">Continue to payment</el-button></div>
                <div style="margin-left:20px;line-height:40px;"><el-link href="/cart" :underline="false">Return to cart</el-link></div>
            </div>
        </div>
        <div v-if="showPayment == 1" id="checkout_payment">
            <div style="border:1px solid #DCDFE6;border-radius:5px;">
                <div class="checkout-payment-info-column">
                    <div style="width:100px;color:#737373;">Contact</div>
                    <div>
                        <div>{{contactName}}</div>
                        <div style="margin-top:5px;color:grey;font-size:12px;">{{contactNumber}}</div>
                    </div>
                </div>
                <div class="checkout-payment-info-column">
                    <div style="width:100px;color:#737373;">Ship to</div>
                    <div style="-webkit-box-flex:1;">
                        <div>{{expectedLocationName}}</div>
                        <div style="margin-top:5px;color:grey;font-size:12px;">{{expectedLocationAddress}}</div>
                    </div>
                </div>
                <div class="checkout-payment-info-column-2">
                    <div style="width:100px;color:#737373;">Method</div>
                    <div>Free Shipping Disccount</div>
                </div>
            </div>
            <div style="font-size:18px;margin-top:40px;">Payment</div>
            <div style="margin-top:20px;">
                <div class="checkout-payment-method-top">
                    <div style="-webkit-box-flex:1;line-height:32px;"><el-radio v-model="paymentMethod" label="sgqr">SGQR</el-radio></div>
                    <div><el-image style="height:32px;width:48px;" fit="fit" :src="require('../../assets/SGQR.jpg')"></el-image></div>
                </div>
            </div>
            <div style="margin-top:20px;display:-webkit-box;">
                <div><el-button type="danger" :loading="paynowLoading" @click="paynowClick">Pay now</el-button></div>
                <div style="margin-left:20px;line-height:40px;"><el-link :underline="false" @click="showPayment = 0">Return to infomation</el-link></div>
            </div>
        </div>
        <div v-if="showPayment == 2" id="checkout_pay_code">
            <div style="text-align:center;"><el-image fit="fit" style="height:48px;width:72px;" :src="require('../../assets/SGQR.jpg')"></el-image></div>
            <div style="text-align:center;margin-top:-20px;"> <vue-qr :text="payRequest.qrCode" :size="300"></vue-qr></div>
            <div style="background-color:#EE1B3B;color:#fff;text-align:center;padding:15px 20px;width:223px;margin:0 auto;border-radius:5px;font-weight:bold;">SAVE QR TO PAY
                <span v-if="isMember">${{cartMemberTotal}}</span>
                <span v-else>${{cartTotal}}</span>
            </div>
            <div style="text-align:center;margin-top:20px;"><el-image style="width:100%;" :src="require('../../assets/banks.png')" fit="fit"></el-image></div>
            <div class="anouncement-div-3"><i class="el-icon-loading"></i>  Payment Pending...Please do not close or leave this webpage before your payment is confirmed.</div>
            <div style="text-align:center;margin-top:10px;"><el-link :underline="false" @click="backToPaynow">Return to payment</el-link></div>
        </div>
        <div class="chdkout-sub-div-bottom">
        </div>
        <div>
            <el-divider></el-divider>
            <div style="display:-webkit-box;">
                <div style="margin-right:10px;"><el-link href="/pages/privacy-policy">Privacy Policy</el-link></div>
                <div><el-link href="/pages/terms-of-service">Terms of Service</el-link></div>
            </div>
        </div>
      </el-col>
  </el-row>
</template>

<script>
import {mapState} from 'vuex'
import CheckoutDeliverItem from '../../components/widgets/CheckoutDeliverItem.vue'
import VueQr from 'vue-qr'
import ModelCheckout from '../viewmodel/ModelCheckout.vue'

export default {
    extends: ModelCheckout,
    name: 'checkout',
    components: {
        CheckoutDeliverItem,
        VueQr
    },
    computed: {
        ...mapState('client', ['browserFrom'])
    },
    methods: {
        backNavClick() {
            window.location.href = 'js://webview?action=back'
        }
    }
}
</script>

<style>
#checkout{
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}
.logo-img-checkout {
    height:40px;
    width: 99px;
}
.checkout-sub-div-m{
    text-align: left;
    padding: 20px 15px;
}
.checkout-sub-div-2-m{
    text-align: left;
    padding: 0px;
}
.right-line {
    border-right: 1px solid #E4E7ED;
    height: 100%;
}
#checkout_infomation{
    margin-top: 40px;
}
.checkout-shipping-method-top{
    display:-webkit-box;
    border-top: 1px solid #E4E7ED;
    border-left: 1px solid #E4E7ED;
    border-right: 1px solid #E4E7ED;
    border-bottom: 1px solid #E4E7ED;
    padding: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.checkout-shipping-method-bottom{
    display: -webkit-box;
    border: 1px solid #E4E7ED;
    padding: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.el-radio__input.is-checked+.el-radio__label{
    color: #000 !important;
}
.el-radio__input.is-checked .el-radio__inner{
    background-color: #000 !important;
    border-color: #000 !important;
}
.dialog-footer{
    display: -webkit-box;
}
.chdkout-sub-div-bottom{
    height: calc(100vh - 800px);
}
.anouncement-div-2{
    background-color:#E6A23C;
    color:#fff;
    padding: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    height: 18px;
    text-align: center;
    border-radius: 2px;
}
.anouncement-div-3{
    margin-top: 10px;
    color:#EE1B3B;
    padding: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    border-radius: 2px;
}
#checkout_payment{
    margin-top: 40px;
}
.checkout-payment-method-top{
    display:-webkit-box;
    border-top: 1px solid #E4E7ED;
    border-left: 1px solid #E4E7ED;
    border-right: 1px solid #E4E7ED;
    border-bottom: 1px solid #E4E7ED;
    padding: 5px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.checkout-payment-info-column {
    display:-webkit-box;
    margin:10px;
    padding:10px;
    border-bottom:1px solid #DCDFE6;
    font-size: 14px;
}
.checkout-payment-info-column-2 {
    display:-webkit-box;
    margin:10px;
    padding:10px;
    font-size: 14px;
}
#checkout_pay_code{
    margin-top: 0px;
}
</style>